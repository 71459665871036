import React from "react";
import { SwmcFooter } from "footer-component";

import "./footerNew.module.css";

const CustomFooter = () => {
  const showNyDisclaimer = process.env.REACT_APP_STATE_DOMAIN !== "ny.swmc.com";

  return (
    <div>
      <SwmcFooter type="swmc" showTacanewLogo={true} nydisclaimer={showNyDisclaimer} />
    </div>
  );
};

export default CustomFooter;
