import React, { useEffect, useState, lazy, Suspense } from 'react';
import { format, parseISO } from "date-fns";
import {Helmet} from "react-helmet";
import axios from "axios";
import "@fontsource/poppins";
import './App.css';
import { PressReleaseComponent } from "press-release";
import { CookieSetting } from "cookiesetting-component";

const ProductsHeader = lazy(() => import("./components/Menubar/ProductsHeader.js"));
const Navbar = lazy(() => import("../src/components/HeaderNav"));
const Header = lazy(() => import("./components/Header"));
const Highlights = lazy(() => import("./components/Highlights"));
const BannerCarousel = lazy(() => import("./components/BannerCarousel"));
const Footer = lazy(() => import("./components/FooterNew/index"));
const LogoSection = lazy(() => import("./components/Logo Section/Logo"));

function App() {
  const imgCDNURL = process.env.REACT_APP_ABOUT203KLOAN_CDN_URL;
  const [themeId, setThemeId] = useState("fha203k-pricing");
  const [configJSON, setConfigJSON] = useState("");

  const [FHA30, setFHA30] = useState("");
  const [pricingAsOfDate, setPricingAsOfDate] = useState("");
  const [siteData, setSiteData] = useState("");

  async function locationError(error) {
    // Get the default price scenario
    await getPrice();
  }

  /**
   * @description Get the pricing config file details
   * 
   */
  const getConfigData = async () => {
    try {

      const configData = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/fha203k-config.json`
      );

      //console.log(JSON.stringify(configData.data[0]));
      if (configData.data[0].FHA_30_YR_FIXED) {
        setConfigJSON(configData.data[0].FHA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load fha203k-config.json file from S3 bucket: ", error);
    }
  };

  /**
   * @description Get the static pricing data from s3.
   * 
   */
  const getPrice = async (userStateCode) => {
    try {
      // Get the static data from s3 for the filtered state else return the default data
      // Get the state list
      const stateList = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/state.json`
      );
      // Filter the state which has the county and zipcode
      const propertyStateData = stateList.data.find(
        (d) => d.state.code === userStateCode
      );
     
      let response;
      if (propertyStateData) {
        const county = propertyStateData.state.county.code;
        const zipcode = propertyStateData.state.county.zipcode;

        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT
          }/${themeId}/${userStateCode.toLowerCase()}/${county}/${zipcode}/products.json`
        ).catch((error) => {
          console.error("Unable to pull pricing for user state: ", error);
          locationError();
        });
      } else {
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT}/${themeId}/default.json`
        );
      }
      
      const parseValue = parseISO(response.data.createdAt);
      const dateTime = format(parseValue, "MM/dd/yyyy hh:mm a");
      setPricingAsOfDate(dateTime);

      if (response.data.FHA_30_YR_FIXED) {
        setFHA30(response.data.FHA_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load todays rate: ", error);
    }
  };

  useEffect(() => {
    let userZipcode = "";
    let userState = "";
    getConfigData();
    // Get the dynamic data from the main JSON and filter the data base on the domain.
    // The about203k.json objects have a field for the domains.
    axios
      .get(
        process.env.REACT_APP_CDN_LINK + 'jsons/products/about203k.json',
        {
          headers: { "Content-Type": "application/json" },
        },
        { timeout: 5000 }
      )
      .then((response) => {
        setSiteData(response.data.filter(site => site.domain.toLowerCase() === window.location.hostname)[0]);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });


    const fetchData = async () => {
    axios
      .post(
        process.env.REACT_APP_MAXMIND,
        {},
        {
          headers: { "Content-Type": "application/json" },
        },
        { timeout: 5000 }
      )
      .then((response) => {
        if (
          response.data !== "IP Not Available" &&
          response.data !== "Invalid Country"
        ) {
          userZipcode = response.data.zipcode;
          if (userZipcode) {
            axios
              .get(
                process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                {
                  headers: { "Content-Type": "application/json" },
                },
                { timeout: 5000 }
              )
              .then((response) => {
                userState = response.data.state;
                // get the price scenario
                getPrice(userState);
              })
              .catch((error) => {
                console.error("Get ZIPCODE Error: ", error);
                locationError();
              });
          } else {
            locationError();
          }
        } else {
          locationError();
        }
      })
      .catch((error) => {
        console.error("Post MAXMIND Error: ", error);
        locationError(error);
      });
    };

    fetchData();
  }, []);

  return (
    <>
      {siteData && <Helmet>
        <meta charSet="utf-8" />
        <title>{siteData.title}</title>
        <meta name="description" content={siteData.description} />
        <meta name="keywords" content={siteData.keywords} />
        <meta property="og:url" content={`https://${window.location.hostname}`} />
        <meta property="og:type" content="website" />
        <meta name="title" property="og:title" content={siteData.title} />
        <meta name="image" property="og:image" content='https://d2w24n4g34usfg.cloudfront.net/about203KLoan/thumbnail.jpg' />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta name="description" property="og:description" content={siteData.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={`https://${window.location.hostname}`} />
        <meta name="twitter:title" content={siteData.title} />
        <meta name="twitter:description" content={siteData.description} />
        <meta name="twitter:image" content="https://d2w24n4g34usfg.cloudfront.net/about203KLoan/thumbnail.jpg" />
      </Helmet>}
      <div className="bodyContainer mb-5">

        <img src={imgCDNURL + "6dots-group.png"} className="dots-group img-fluid" alt="6dots-group" />
        <img src={imgCDNURL + "desktop-bg.webp"} className="desktop-bg img-fluid" alt="desktop-bg" />
        <img src={imgCDNURL + "mobile-bg.png"} className="mobile-bg img-fluid" alt="mobile-bg" />
        <Suspense fallback={<div />}>
          <Navbar />
        </Suspense>

        {/* <Suspense fallback={<div />}>
          <ProductsHeader />
        </Suspense> */}

        <Suspense fallback={<div />}>
          <Header configJSON={configJSON} FHA30={FHA30} pricingAsOfDate={pricingAsOfDate} />
        </Suspense>
      </div>
      <Suspense fallback={<div />}>
      <LogoSection fetchpriority="low" />
      </Suspense>
      <Suspense fallback={<div />}>
        <Highlights siteData={siteData} />
      </Suspense>
      <Suspense fallback={<div />}>
        <div className="news pt-4">
          <PressReleaseComponent />
        </div>
      </Suspense>
      <Suspense fallback={<div />}>
        <BannerCarousel title="Video Testimonials" />
      </Suspense>
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
      <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </>
  );
}

export default App;
